import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,

} from '@material-ui/core';
import defaultImage from '../../assets/default-img.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customIconButton: {
      padding: '5px',
      '& .MuiIconButton-root': {
        padding: '5px !important',
      },
    },
    carouselImage: {
      width: '100%',
      cursor: 'pointer',
      height: '600px',
      objectFit: 'fill',
      objectPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: ' auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '350px',
      },
    },
    loadingBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
    },
    dialogContent: {
      padding: 0,
      textAlign: 'center',
    },
    dialogImage: {
      width: '100%',
      height: '500px',
      objectFit: 'contain',
      [theme.breakpoints.down('xs')]: {
        height: '350px',
      },
    },
  }),
);

interface CarouselProps {
  images: string[] ;
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const sliderSettings = {
    dots: images && images?.length > 1,
    arrows: false,
    infinite: images && images?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dotsClass: 'button__bar',
  };
  
   // Slider settings for the dialog carousel
   const dialogSliderSettings = {
    dots: images && images?.length > 1,
    arrows: false,
    infinite: images && images?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dotsClass: 'button__bar',
    initialSlide: currentImageIndex,
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(false);
    }
  }, [images]);



  return (
    <Box position="relative" width="100%" >
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Slider {...sliderSettings}
        >
          {images?.length > 0 ? (
            images?.map((image, index) => (
              <div 
              style={{
                // height: xsDown? '350px' : '500px'
                height: xsDown ? 'auto' : 'calc(100% * 3 / 4)',
                }}
                key={index}>
                <img
                  src={image}
                  alt={`Event ${index}`}
                  className={classes.carouselImage}
                  onLoad={() => setIsLoading(false)}
                  onClick={() => handleImageClick(index)}
                  onError={e => {
                    (e.target as HTMLImageElement).src = defaultImage;
                  }}
                />
              </div>
            ))
          ) : (
            <div 
            style={{
              // height: xsDown? '350px' : '500px'
              height: xsDown ? 'auto' : 'calc(100% * 3 / 4)',
              }}
              >
              <img
                src={defaultImage}
                alt="Default Event"
                className={classes.carouselImage}
              />
            </div>
          )}
        </Slider>
      )}

      {/* Dialog for displaying images */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          className={classes.dialogContent}
          style={{ overflow: 'hidden', position: 'relative' }}
        >
          <Slider {...dialogSliderSettings}
          >
            {images?.map((image, index) => (
              <div key={index}>
                <img
                  src={image || defaultImage}
                  alt={`Event ${index}`}
                  className={classes.dialogImage}
                  onError={e => {
                    (e.target as HTMLImageElement).src = defaultImage;
                  }}
                />
              </div>
            ))}
          </Slider>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Carousel;
