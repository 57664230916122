import React, { useEffect, useState } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Grid,
  Typography,
  CardContent,
  Card,
  Avatar,
  IconButton,
  CircularProgress,
  MenuItem,
  Menu,
  ListItemIcon,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Dialog,
  DialogContent,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { useHistory } from 'react-router';
import { FiEdit, FiMapPin, FiRadio } from 'react-icons/fi';
import { firestore } from '../../utils/firebase-utils';
import Address from '../../models/[new]address';
import 'firebase/firestore';
import firebase from 'firebase/app';
import { format, formatDistanceToNow } from 'date-fns';
import { MdUpdate } from 'react-icons/md';
import { useTypedSelector } from '../../redux/store';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useSnackbar, OptionsObject as SnackbarOptions } from 'notistack';
import defaultImage from '../../assets/default-img.png';
import search from '../../assets/search.png';
import noDataIcon from '../../assets/undraw_Web_search_re_efla.svg';
import Carousel from './Carousel';
import { Helmet } from 'react-helmet';
import AppDialog from './components/AppDialog';
import CalendarDate from '../../assets/Icons/Calendar-date.png';
import FilterByDate from './components/FilterByDate';
import moment, { Moment } from 'moment';

interface EventData {
  address: Address;
  content: string;
  eventCost: number;
  description: string;
  createdAt: firebase.firestore.Timestamp;
  event_date: firebase.firestore.Timestamp;
  event_end_date: firebase.firestore.Timestamp;
  id: string;
  images: string[];
  profilePicture: string;
  title: string;
  type: string;
  uid: string;
  userName: string;
}

interface CustomTimestamp {
  seconds: number;
  nanoseconds: number;
}

const snackbarCommonOpt: SnackbarOptions = {
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '97%',
      margin: '3rem auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    noDataWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      marginTop: '2rem',
    },
    noDataImage: {
      maxWidth: '100%',
      marginBottom: '1rem',
    },
    categoryButtons: {
      display: 'flex',
      gap: '10px',
      marginTop: '30px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    searchImg: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      position: 'absolute',
      top: '15px',
      right: '40px',
      [theme.breakpoints.down('md')]: {
        // right: '247px',
        // right: '254px',
        right: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        right: '30px',
      },
    },
    noBorder: {
      width: '100%',
      padding: '9px 13px',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      textAlign: 'left',
      color: '#141414',
      backgroundColor: '#fff',
      border: '1px solid #E7E7E7',
      height: '52px',
      borderRadius: '8px',
      '& .MuiInputBase-input:focus': {
        border: 'none !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 14px !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    responsiveContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '40px',
      [theme.breakpoints.down('sm')]: {
        gap: '10px',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    eventButton: {
      backgroundColor: '#B87A4E',
      borderRadius: '50px',
      padding: '10px 60px',
      color: '#fff',
      fontWeight: 600,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: '#B87A4E',
      },
    },
    auctionButton: {
      backgroundColor: '#fff',
      borderRadius: '50px',
      padding: '10px 60px',
      color: '#000',
      fontWeight: 600,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    paddedBox: {
      padding: '0 10px',
      [theme.breakpoints.up('md')]: {
        padding: '0 50px',
      },
    },
    customDialog: {
      '& .MuiPaper-rounded': {
        borderRadius: '30px !important',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      paddingBottom: '40px',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '10px',
      },
    },
    menu: {
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
    },
    menuItem: {
      color: '#42526E',
      fontWeight: 600,
      '&:hover': {
        color: 'red',
        '& .MuiListItemIcon-root': {
          color: 'red',
        },
      },
    },
    icon: {
      color: '#42526E',
      minWidth: '30px',
    },
    btnsWrapper: {
      width: '80%',
      display: 'flex',
      backgroundColor: '#fff',
      borderRadius: '5px',
      padding: '1rem 2rem',
      alignItems: 'center',
      position: 'relative',
      top: '30px',
      marginBottom: '55px',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      },
    },
    imgBtn: {
      height: '2.5rem',
      color: '#898989',
      fontFamily: 'Poppins',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      borderRadius: '.25rem',
      border: '1px solid #EAEAEA',
      marginLeft: '30px',
      padding: '0 30px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        marginBottom: '10px',
        padding: '0px 10px',
      },
    },
  }),
);

const initialValueOfDate = {
  from: '',
  to: '',
};

const Events = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [eventData, setEvents] = useState<EventData[]>([]);
  const { uid } = useTypedSelector(state => state.user);
  const [lastVisible, setLastVisible] =
    useState<firebase.firestore.DocumentSnapshot | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);
  const [isAuctionDialogOpen, setAuctionDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [showDateFilterOptions, setShowDateFilterOptions] = useState(false);
  const [DateFilterOptions, setDateFilterOptions] =
    useState(initialValueOfDate);
    const [filteredEvents, setFilteredEvents] = useState<EventData[]>([]);

  const handleEventClick = () => {
    history.push('/list-event');
  };

  const toggleDateFilterOptions = () => {
    setShowDateFilterOptions(prev => !prev);
  };

  const fetchEvents = async (isNext = false) => {
    setIsLoading(true);
    try {
      let query = firestore
        .collection('events')
        .orderBy('createdAt', 'desc')
        .where('type', '==', 'normalEvent')
        .limit(10);

      if (isNext && lastVisible) {
        query = query.startAfter(lastVisible);
      }

      const snapshot = await query.get();

      const fetchedEvents: EventData[] = snapshot.docs.map(doc => {
        const data = doc.data() as Omit<EventData, 'id'>;
        return {
          id: doc.id,
          ...data,
        };
      });

      setEvents(prevEvents => [...prevEvents, ...fetchedEvents]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(); // Load first batch of events
  }, []);
  
  const closeDateFilter = () => {
    setShowDateFilterOptions(false);
    
    if (filteredEvents.length > 0) {
      setFilteredEvents([]); 
      fetchEvents();
    }
  };

  const convertTimestampToDate = (timestamp: CustomTimestamp): Date => {
    return new Date(timestamp.seconds * 1000);
  };

  const formatAddress = (address: {
    city?: string;
    state?: string;
    country?: string;
    addressLineOne?: string;
  }): string => {
    if (!address) return 'No address found';

    const { city, state, country, addressLineOne } = address;
    const addressParts = [city, state, country].filter(Boolean).join(', ');

    if (addressParts) {
      return addressParts;
    } else if (addressLineOne) {
      return addressLineOne;
    } else {
      return 'No address found';
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    eventId: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedEvent(eventId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEvent(null);
  };

  const handleEdit = (id: string) => {
    history.push(`/list-event/${id}`);
    handleMenuClose();
  };

  const handleDeleteEvent = async () => {
    if (!eventToDelete) return;
    try {
      await firestore.collection('events').doc(eventToDelete).delete();
      setEvents(prevEvents =>
        prevEvents.filter(event => event?.id !== eventToDelete),
      );
      setDeleteDialogOpen(false);
      handleMenuClose();
      enqueueSnackbar('Event deleted successfully!', {
        variant: 'success',
        ...snackbarCommonOpt,
      });
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const confirmDelete = (eventId: string) => {
    setEventToDelete(eventId);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const formatCreatedAt = (
    createdAt: firebase.firestore.Timestamp | null,
  ): string => {
    if (!createdAt) return 'Invalid Date';

    const createdAtDate = createdAt.toDate();
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;

    if (now.getTime() - createdAtDate.getTime() < oneDay) {
      return `${formatDistanceToNow(createdAtDate, { addSuffix: true }).replace(
        'about ',
        '',
      )}`;
    } else if (now.getTime() - createdAtDate.getTime() < oneWeek) {
      return `Last ${format(createdAtDate, 'EEEE')} at ${format(
        createdAtDate,
        'hh:mm a',
      )}`;
    } else {
      return format(createdAtDate, 'MMM d, yyyy hh:mm a');
    }
  };

  const formatPrice = (price: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.trim();
    setSearchQuery(term);

    if (term === '') {
      setEvents([]);

      fetchEvents();
    } else {
      const filtered = eventData?.filter(event =>
        event?.title?.toLowerCase().includes(term.toLowerCase()),
      );
      setEvents(filtered);
    }
  };

  const jsonLdSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Antique Events and Auctions',
    description:
      "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'",
    url: 'https://antiquesmart.com/events',
  };

  useEffect(() => {
    // Update title
    document.title = 'Antique Events and Auctions';

    // Keywords meta tag
    const keywordsContent =
      'Antiquesmart Events, Antique Auctions, Vintage Auctions, Online Antique Events, Antique Shows and Fairs, Antique Marketplace Auctions, Rare Item Auctions, Antique Collectors Events, Live Auctions for Antiques, Online Bidding for Antiques, Upcoming Antique Events, Auction Listings, Antique Exhibitions, Vintage Collectible Auctions, Special Antique Sales';
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.setAttribute('name', 'keywords');
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute('content', keywordsContent);

    // Description meta tag
    const descriptionContent =
      "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'";
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', descriptionContent);

    // Author meta tag
    const authorContent = 'Antiquesmart';
    let metaAuthor = document.querySelector('meta[name="author"]');
    if (!metaAuthor) {
      metaAuthor = document.createElement('meta');
      metaAuthor.setAttribute('name', 'author');
      document.head.appendChild(metaAuthor);
    }
    metaAuthor.setAttribute('content', authorContent);

    // Open Graph meta tags
    const ogProperties = [
      {
        property: 'og:title',
        content: 'Antique Events and Auctions | Antiquesmart',
      },
      { property: 'og:description', content: descriptionContent },
      {
        property: 'og:image',
        content: 'https://antiquesmart.com/events-image.jpg',
      },
      { property: 'og:url', content: 'https://antiquesmart.com/events' },
      { property: 'og:type', content: 'website' },
    ];

    ogProperties.forEach(({ property, content }) => {
      let metaTag = document.querySelector(`meta[property="${property}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // Twitter meta tags
    const twitterProperties = [
      {
        name: 'twitter:title',
        content: 'Antique Events and Auctions | Antiquesmart',
      },
      {
        name: 'twitter:description',
        content:
          "'Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events.'",
      },
      {
        name: 'twitter:image',
        content: 'https://antiquesmart.com/events-image.jpg',
      },
      { name: 'twitter:card', content: 'summary_large_image' },
    ];

    twitterProperties.forEach(({ name, content }) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('name', name);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });

    // json-ld Schema
    const jsonLdScript = document.querySelector(
      'script[type="application/ld+json"]',
    );
    if (jsonLdScript) {
      jsonLdScript.innerHTML = JSON.stringify(jsonLdSchema);
    } else {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(jsonLdSchema);
      document.head.appendChild(script);
    }
  }, []);

  const fetchFilteredEvents = async (dates: { from: Moment; to: Moment }) => {
    
    const fromDate = dates.from.toDate().getTime();
    const toDate = dates.to.toDate().getTime();

    setIsLoading(true);
    try {
      const snapshot = await firestore
        .collection('events')
        .orderBy('createdAt', 'desc')
        .where('type', '==', 'normalEvent')
        .get();

      const filteredEvents: EventData[] = snapshot.docs
        .map(doc => {
          const data = doc.data() as Omit<EventData, 'id'>;
          return {
            id: doc.id,
            ...data,
          };
        })
        .filter(event => {
          const eventStartDate = event?.event_date.seconds * 1000;
          const eventEndDate = event?.event_end_date.seconds * 1000;

          return (
            (eventStartDate >= fromDate && eventStartDate <= toDate) ||
            (eventEndDate >= fromDate && eventEndDate <= toDate)
          );
        });

        setFilteredEvents(filteredEvents);
        setEvents([]);
        
    } catch (error) {
      console.error('Error fetching filtered events:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const [selectedDates, setSelectedDates] = useState<{ from: Moment | null; to: Moment | null }>({
    from: null,
    to: null,
  });


// const formattedDates = {
//   from: selectedDates.from ? selectedDates.from.format("MM/DD/YYYY") : null,
//   to: selectedDates.to ? selectedDates.to.format("MM/DD/YYYY") : null,
// };


  const applyDateFilter = (dates: { from: Moment | null; to: Moment | null }) => {
  
    setSelectedDates(dates);

    if (!dates.from || !dates.to) {
      setFilteredEvents([]);
      fetchEvents();
      return;
    }
    
    if (dates.from && dates.to) {
      fetchFilteredEvents({
        from: dates.from,
        to: dates.to,
      });
    }
  };
  

  const eventsToShow = filteredEvents.length > 0 ? filteredEvents : eventData;

  return (
    <>
      <Helmet>
        <title>Antique Events and Auctions | Antiquesmart</title>
        <meta
          name="description"
          content="Stay updated on upcoming antique events and auctions at Antiquesmart. Discover rare items and unique finds at our exclusive events."
        />
      </Helmet>

      <div
        style={{
          backgroundColor: 'rgb(176 196 222 / 17%)',
          width: '100%',
        }}
      >
        <div style={{ margin: '0 40px' }}>
          <Box className={classes.btnsWrapper}>
            <Button className={classes.imgBtn} onClick={handleEventClick}>
              <NoteAddOutlinedIcon style={{ marginRight: '10px' }} />
              List An Event
            </Button>
            <Button
              className={classes.imgBtn}
              onClick={() => setAuctionDialogOpen(true)}
            >
              <FiRadio style={{ marginRight: '10px', fontSize: '25px' }} />
              Schedule an Auction
            </Button>
          </Box>

          {/* Search Box && Filter_date*/}
          <Grid container style={{ gap: '10px' }}>
            <Grid item xs={12} md={12} lg={8}>
              <Box style={{ position: 'relative' }}>
                <TextField
                  variant="outlined"
                  placeholder="Search events by title..."
                  value={searchQuery}
                  onChange={handleSearch}
                  className={classes.noBorder}
                />
                <img src={search} alt="Search" className={classes.searchImg} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  position: 'relative',
                  display: mdDown ? 'flex' : '',
                  justifyContent: mdDown ? 'flex-end' : '',
                }}
              >
                <Button
                  className="filter-button"
                  onClick={toggleDateFilterOptions}
                  variant="outlined"
                  style={{
                    color: 'black',
                    borderRadius: '8px',
                    minHeight: '50px',
                    // border: 'none',
                    textTransform: 'capitalize',
                    minWidth: '160px',
                    display: 'flex',
                    justifyContent: 'center',
                    // backgroundColor: 'white',
                    backgroundColor: selectedDates.from || selectedDates.to ? '#F0F8FF' : 'white', 
                    border: selectedDates.from || selectedDates.to ? '1px solid #007bff' : 'none',
                  }}
                >
                  <img src={CalendarDate} alt="Calendar-date" />
                  <span
                    style={{
                      color: '#7A869A',
                      fontSize: '14px',
                      lineHeight: '17.64px',
                      marginLeft: '10px',
                      fontWeight: 400,
                    }}
                  >
                    {' '}
                    Filter by date
                  </span>
                   {/* Indicator dot */}
    {selectedDates.from || selectedDates.to ? (
      <span
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: '#007bff', // Blue color to show active filter
          borderRadius: '50%',
          display: 'inline-block',
          marginLeft: '8px',
        }}
      ></span>
    ) : null}
                </Button>
                {showDateFilterOptions && (
                  <FilterByDate
                    onClose={closeDateFilter}
                    onApply={applyDateFilter}
                    savedFilters={selectedDates}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.categoryButtons}>
            <Button className={classes.eventButton}>
              {/* <FiRadio style={{ marginRight: '10px', fontSize: '25px' }} /> */}
              <NoteAddOutlinedIcon style={{ marginRight: '10px' }} />
              Events
            </Button>
            <Button
              className={classes.auctionButton}
              onClick={() => setAuctionDialogOpen(true)}
            >
              <FiRadio style={{ marginRight: '10px', fontSize: '25px' }} />
              Auctions
            </Button>
          </Box>
          <AppDialog
            open={isAuctionDialogOpen}
            onClose={() => setAuctionDialogOpen(false)}
          />
        </div>
        {eventsToShow?.length === 0 && !isLoading ? (
          <div className={classes.noDataWrapper}>
            <img
              src={noDataIcon}
              alt="no data"
              width={500}
              className={classes.noDataImage}
              loading="lazy"
            />
            <Typography
              component="p"
              variant="h5"
              style={{ textAlign: 'center' }}
            >
              We couldn’t find what you are looking for
            </Typography>
          </div>
        ) : (
          <>
            <Grid container spacing={3} className={classes.root}>
              {eventsToShow?.map(event => (
                <Grid item xs={12} md={9} key={event?.id}>
                  <Card style={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={2}
                        style={{
                          background: '#fff',
                          padding: '20px 10px 0 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => history.push(`/profile/${event?.uid}`)}
                      >
                        <Avatar
                          src={event?.profilePicture || 'default-avatar.jpg'}
                          alt={event?.userName}
                          style={{ marginRight: '10px' }}
                        />
                        <Box>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 600, color: '#000000' }}
                          >
                            {event?.userName && event?.userName.trim() !== ''
                              ? event?.userName
                              : 'No User Name Available'}
                          </Typography>

                          <Typography variant="subtitle2" color="textSecondary">
                            {formatCreatedAt(event?.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {event?.uid === uid && (
                          <IconButton
                            onClick={e => handleMenuOpen(e, event?.id)}
                          >
                            <HiDotsVertical />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    {/* Dropdown Menu */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedEvent === event?.id}
                      onClose={handleMenuClose}
                      classes={{ paper: classes.menu }}
                    >
                      <MenuItem
                        onClick={() => handleEdit(event?.id)}
                        className={classes.menuItem}
                      >
                        <ListItemIcon className={classes.icon}>
                          <FiEdit />
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{ fontWeight: 600 }}
                        >
                          Edit
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => confirmDelete(event?.id)}
                        className={classes.menuItem}
                      >
                        <ListItemIcon className={classes.icon}>
                          <RiDeleteBin6Line />
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{ fontWeight: 600 }}
                        >
                          Delete
                        </Typography>
                      </MenuItem>
                    </Menu>

                    <Box
                      position="relative"
                      style={{
                        height: xsDown ? 'auto' : 'calc(100% * 3 / 4)',
                      }}
                    >
                      <Carousel images={event?.images || [defaultImage]} />
                    </Box>

                    <CardContent>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            color: '#1A202C',
                            fontWeight: 700,
                            fontSize: '20px',
                          }}
                        >
                          {event?.title?.length > 30
                            ? `${event?.title?.slice(0, 30)}...`
                            : event?.title}
                        </Typography>
                        <Box className={classes.responsiveContainer}>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FiMapPin
                              style={{
                                color: '#D4D4D4',
                                marginRight: '10px',
                                fontSize: '25px',
                              }}
                            />
                            <Typography
                              variant="body2"
                              style={{ color: '#5A5C5E', fontSize: '.8rem' }}
                            >
                              {formatAddress(event?.address)}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <MdUpdate
                              style={{
                                color: '#D4D4D4',
                                marginRight: '10px',
                                fontSize: '25px',
                              }}
                            />
                            <Typography
                              variant="body1"
                              style={{ color: '#5A5C5E', fontSize: '.8rem' }}
                            >
                              {event?.event_date && event?.event_end_date ? (
                                <>
                                  {format(
                                    convertTimestampToDate(event?.event_date),
                                    'd MMMM yyyy',
                                  )}
                                  <br />
                                  {`${format(
                                    convertTimestampToDate(event?.event_date),
                                    'hh:mm a',
                                  )} - ${format(
                                    convertTimestampToDate(
                                      event?.event_end_date,
                                    ),
                                    'hh:mm a',
                                  )}`}
                                </>
                              ) : (
                                'Invalid event date'
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '10px',

                          alignItems:
                            xsDown && event?.eventCost?.toString().length > 6
                              ? 'flex-start'
                              : 'center',
                          flexDirection:
                            xsDown &&
                            event?.eventCost &&
                            event?.eventCost.toString().length > 6
                              ? 'column'
                              : 'row',
                          gap:
                            xsDown && event?.eventCost?.toString().length > 6
                              ? '15px'
                              : '0px',
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            color: '#AB7254',
                            fontWeight: 800,
                            fontSize: '20px',
                          }}
                        >
                          {event?.eventCost === 0
                            ? 'Free'
                            : `$${formatPrice(event?.eventCost)}`}
                        </Typography>
                        <Button
                          style={{
                            backgroundColor: '#B87A4E',
                            borderRadius: '50px',
                            padding: '10px 50px',
                            color: '#fff',
                            fontWeight: 600,
                            textTransform: 'capitalize',
                            marginRight: '10px',
                            width:
                              xsDown && event?.eventCost?.toString().length > 6
                                ? '100%'
                                : 'auto',
                          }}
                          onClick={() =>
                            history.push(`/event-details/${event?.id}`)
                          }
                        >
                          View Details
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this event?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setDeleteDialogOpen(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={handleDeleteEvent} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {isLoading ? (
          <Box
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            style={{
              textAlign: 'center',
              paddingBottom: '40px',
            }}
          >
            {!isLoading && lastVisible && eventData?.length > 9 && (
              <Button
                onClick={() => fetchEvents(true)}
                style={{
                  backgroundColor: '#B87A4E',
                  color: '#fff',
                  borderRadius: '50px',
                  padding: '10px 30px',
                  marginTop: '20px',
                  textTransform: 'capitalize',
                }}
              >
                Load More
              </Button>
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default Events;
